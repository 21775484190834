.logo {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
}

.center-logo {
    width: 100%;
    
    
}

.center-logo h1 , p{
    margin: 0;
    width: 100%;
    
}
.center-logo h1 {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.center-logo a {
    text-decoration: none;
    color: black;
    font-size: 25px;
    padding : 3%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

 a:hover h1{
    
    font-weight: 800;
}

@media only screen and (max-width: 375px){

    .center-logo h1 {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
  
  }