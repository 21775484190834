
.over{
    
    overflow: scroll;
}

.cards {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 15px;
    justify-items: center;
    border-radius: 10px;
    background-color: white;
    padding: 15px 10px 15px;
}



.card p {
    display: flex;
    justify-content: center;
    position: absolute;
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    bottom: 23px;
    color: #f6f6f6;
}

.card img {
    width: 400px;
    height: 300px;
    position: relative;
    border-radius: 10px;
    object-fit: cover;
    
}

.card :hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.galery p {
    display: flex;
    justify-content: center;
    align-items: center;
}

h1 {
    display: flex;
    justify-content: center;
    font-style: italic;
}

.cardwrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: #e2e2e2;
    border-radius: 10px;
    transition: 200ms;
    font-style: italic;
}

.cardwrapper:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px #e2e3e9;
}

.cardwrapper img{
    border-radius: 10px;
    height: 300px;
    width: 400px;
}



/*Desktop*/
@media only screen and (min-width: 1440px) {
    .over{
        height: 750px;
    }

    .cards {
        grid-template-columns: repeat(4, auto);
        grid-gap: 10px;
        background-color: white;
        padding: 15px 20px 10px;  
    }

    .card img {
        width: 400px;
        height: 300px;
        position: relative;
        border-radius: 20px;
        object-fit: cover;
    }

}
  
  
  /*Large Laptop*/
  @media only screen and (max-width: 1440px) {
    .over{
        height: 500px;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-gap: 15px;
        justify-items: center;
        border-radius: 15px;
        background-color: white;
        padding: 10px 10px 15px;   
        
    }
    .card img {
        width: 325px;
        height: 250px;
    }
  }

  /*Laptop*/
  @media only screen and (max-width: 1024px) {

    .over{
        height: 700px;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 15px;
        justify-items: center;
        border-radius: 15px;
        background-color: white;
        padding: 10px 10px 15px;   
        
    }
  }
  
  /*Tablet*/
  @media only screen and (max-width: 768px) {
    .cards {
        display: grid;
        grid-template-columns: repeat(1, auto);
        grid-gap: 5px;
        justify-items: center;
        border-radius: 25px;
        background-color: white;
        padding: 50px 10px 5px;
        height: 90%;
    }
    .cardwrapper img{
        border-radius: 10px;
        height: 400px;
        width: 550px;
    }
    
  }
  @media only screen and (max-width: 550px) {
    .cards {
       
        grid-template-columns: repeat(1, auto);
        padding: 50px 20px 5px;
    }
    .card img {
        width: 400px;
        height: 300px;
    }
    
  }
  
  /*MobileL*/
  @media only screen and (max-width: 425px) {
    .over{
        height: 400px;
    }
    .cards {
       
        padding: 10px 5px 5px;
    }
    .cardwrapper img{
        border-radius: 10px;
        height: 300px;
        width: 350px;
    }
    
  }
  
  /*MobileM*/
  @media only screen and (max-width: 375px) {
    .over{
        height: 400px;
    }
    .cards {
       
        padding: 20px 5px 5px;
    }
    .cardwrapper img{
        border-radius: 10px;
        height: 275px;
        width: 325px;
    }

  }
  
  /*MobileS*/
   @media only screen and (max-width: 325px) {
    .over{
        height: 300px;
    }
    .cardwrapper img{
    border-radius: 10px;
    height: 250px;
    width: 300px;
}
  }
   
  
  