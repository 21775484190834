.App {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  width:100%;
  overflow: scroll;
  
}

.carousel{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;  
}