.Header {
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #7d8491;
    width: 100%;
    height: auto;
    font-size: calc(10px + 2vmin);
    font-style: italic;
    position: relative;
    margin: 0;
    padding: 0;
    

}

@media (max-width: 1440px) {
    .Header {
        
        position: relative;
    }
}

@media (max-width: 1024px) {
    .Header {
        
        position: relative;
    }
}

