.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: 7d8491;
    width: 100%;
}

.nav-links a {
    text-decoration: none;
    color: black;
    font-size: 25px;
    padding : 3%
}

.nav-links a:hover {
    text-decoration: none;
    font-weight: 700;
}

.nav-links .active {
    
  color: black;
  text-decoration: none;
}

.logo{
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

@media only screen and (max-width: 375px){

  .nav-links a {
    text-decoration: none;
    color: black;
    font-size: 15px;
    padding : 3%
}

}
