.Picture {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 1100px;
    
}

.Picture img{
   border-radius: 10px;
    
}

/*Desktop*/
@media only screen and (min-width: 1440px) {
    .Picture {
       
        height: 750px;
    
    }
    .Picture img{
        height: 650px;
        width: 1200px;
    }
}
   /*Large Laptop*/
  @media only screen and (max-width: 1440px) {
    .Picture {
        height: 500px;
    }
    .Picture img{
        height: 415px;
        width: 900px;
        
    }
  }

  /*Laptop*/
  @media only screen and (max-width: 1024px) {
    .Picture {
        height: 720px;
    }
    .Picture img{
        height: 600px;
        width: 900px;
        
    }
  }
  
  
  
  /*Tablet*/
  @media only screen and (max-width: 768px) {
    .Picture {
        height: 675px;
        
    }
    .Picture img{
        height: 600px;
        width: 700px;
        
    }
   
  }
  
  /*MobileL*/
  @media only screen and (max-width: 425px) {

    .Picture{
        height: 500px;
    }
    .Picture img{
        height: 400px;
        width: 400px;
    }
  }
  
  /*MobileM*/
  @media only screen and (max-width: 375px) {

    .Picture{
        height: 450px;
    }
    .Picture img{
        height: 350px;
        width: 350px;
    }
  }
  
  /*MobileS*/
  @media only screen and (max-width: 320px) {

    .Picture {
        height: 350px;
    }
    
    .Picture img{
        height: 300px;
        width: 300px;
    }
    
  }


