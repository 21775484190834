.Login {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 450px;
}

@media (min-width: 1400px) {
    .Login {
        height: 750px;
    }
}